import * as React from "react";

import { SEO, notEmpty } from "@parataxic/shared-ui";

import "./ListView.css";

type ListQueryEdges<P> = ReadonlyArray<{ readonly node: P }>;

export function ListView<ObjectType extends { title: string }>({
  edges,
  ListItem,
  seoProps,
}: {
  edges: ListQueryEdges<ObjectType>;
  ListItem: React.FC<{ item: ObjectType }>;
  seoProps: React.ComponentProps<typeof SEO>;
}): React.ReactElement {
  const initialItems = React.useMemo<ObjectType[]>(
    () => edges.map(({ node }) => node),
    [edges],
  );
  const [items, setItems] = React.useState(initialItems);

  React.useEffect(() => setItems(initialItems.filter(notEmpty)), [edges]);
  return (
    <div className="list-view">
      <SEO {...seoProps} />
      <div>
        <div role="list">
          {items?.map((item: ObjectType, index: number) => (
            <ListItem item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListView;
