import * as React from "react";

import { graphql } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";

import { Card, LinkList, SiteLink } from "@parataxic/shared-ui";

import { ListView } from "../components/ListView";
import { PopoverSiteLink } from "../components/Popover";

interface IVideoItemProps {
  item: GatsbyTypes.VideoMediaItemFragment;
}

export const getYoutubeID = (
  // Not used anymore since we have video.thumbnail
  url: string,
): GatsbyTypes.Maybe<string> =>
  url?.match(
    /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^/&]{10,12})/,
  )?.[1];

const VideoItem: React.FC<IVideoItemProps> = ({ item }) => {
  return (
    <Card
      role="listitem"
      variant="elevated"
      style={{
        gridTemplateColumns: "2fr 8fr", // 1fr 1fr 6fr 1fr 6fr 2fr 8fr
        gridColumn: "5/6",
        gridGap: 3,
        display: "grid",
        maxWidth: "1000px",
        padding: ".5rem",
        marginBottom: "1rem",
      }}
    >
      {item.youtubeLink && (
        <div
          style={{
            display: "grid",
            minWidth: "195px",
            gridTemplateColumns: "1", // 1/10 on desktop
            position: "relative",
            justifySelf: "center",
            width: "100%",
          }}
        >
          <Img
            image={item?.thumbnail?.childImageSharp?.gatsbyImageData}
            alt={item.title}
          />
          {item.duration && (
            <span
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                fontSize: "0.7rem",
                fontWeight: "600",
                backgroundColor: "rgba(0,0,0,0.8)",
                color: "white",
                lineHeight: "normal",
                display: "inline-flex",
                alignItems: "center",
                margin: ".15rem",
                paddingBottom: ".1rem",
                padding: "0 .1rem",
              }}
            >
              {item.duration}
            </span>
          )}
        </div>
      )}
      <div
        style={{
          gridTemplateColumns: "1fr",
          gridColumn: "2",
        }}
      >
        <SiteLink
          href={item.slug}
          style={{
            width: "100%",
            color: "var(--color-gray-800)",
          }}
        >
          {item.title}
        </SiteLink>
        {item.description && (
          <div
            style={{
              fontSize: "0.9rem",
              alignSelf: "end",
              gridColumn: "1/8",
              color: "var(--color-gray-500)",
              padding: ".5rem 0",
            }}
          >
            {item.description}
          </div>
        )}

        {item.mentionedTheories && (
          <div
            style={{
              gridColumn: "1/2",
              alignSelf: "end",
              textAlign: "left",
              fontSize: "0.8rem",
            }}
          >
            <LinkList
              links={item.mentionedTheories}
              Component={PopoverSiteLink}
            />
          </div>
        )}
        {item.creators && (
          <div style={{ alignSelf: "end", fontSize: ".8rem" }}>
            <LinkList links={item.creators} Component={PopoverSiteLink} />
          </div>
        )}
        {item.mentionedPsychologists && (
          <div style={{ alignSelf: "end", textAlign: "right" }}>
            <LinkList
              links={item.mentionedPsychologists}
              Component={PopoverSiteLink}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

const View = ({
  data,
}: {
  data: GatsbyTypes.VideoMediaListQuery;
}): React.ReactNode => {
  return (
    <ListView<GatsbyTypes.VideoMediaItemFragment>
      edges={data.allMdxVideo.edges}
      ListItem={VideoItem}
      seoProps={{
        title: "Psychoanalysis / family therapy videos - Watch and learn",
      }}
    />
  );
};

export const mediaQuery = graphql`
  fragment VideoMediaItem on MdxVideo {
    id
    title
    description
    youtubeLink
    thumbnail {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: CONSTRAINED
        )
      }
    }
    creators {
      ...PsychologistAvatar
    }
    mentionedPsychologists {
      ...PsychologistAvatar
    }
    mentionedTheories {
      id
      title
      slug
    }
    duration
    slug
  }

  query VideoMediaList {
    allMdxVideo {
      edges {
        node {
          ...VideoMediaItem
        }
      }
    }
  }
`;

export default View;
